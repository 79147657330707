// import React, { useRef, useEffect } from "react";
import React from "react";
import CleanLayout from "layouts/Clean";
import { SEO, Title, Button, Text, Box, Intro, ReadMore } from "components";

import img1 from "../images/test-etic.png";
import img2 from "../images/04.png";
import img3 from "../images/01.png";
import img4 from "../images/08.png";
import img5 from "../images/11.png";
import img6 from "../images/05.png";

const pageProperties = {
  title: "Web marketing Firenze, Prato e Pistoia: più visite e vendite sul tuo sito",
  description:
    "E' il momento di portare il tuo business ad un livello successivo. Grazie alle giuste strategie di web marketing puoi uscire dai confini di Prato, Firenze e Pistoia!",
};


function WebMarketingLocalPage() {
  return (
    <CleanLayout>
      <SEO
        title="Web marketing Firenze, Prato e Pistoia: più visite e vendite sul tuo sito"
        description="E' il momento di portare il tuo business ad un livello successivo. Grazie alle giuste strategie di web marketing puoi uscire dai confini di Prato, Firenze e Pistoia!"
      />

      {/* <div className="w-full md:mt-32" ref={ref}> */}
      <div className="w-screen ">
        <section className="px-8 md:px-28">
          <Intro {...pageProperties} />
        </section>

        <section className="intro-page">
            <div className="flex flex-col items-left px-8 py-16" style={{ 'column-count': 2, 'column-gap': '30px' }} >
                Il web marketing non è una pozione miracolosa. 
                Fare campagne su google ads o iniare una gestione social servirà a ben poco se prima non avrai messo a punto tutti i tasselli necessari.<br/>
                Trame Digitali è la web agency di Prato, in via Frà Bartolomeo 82/A, che ti guiderà in tutti i passaggi necessari per dotarti di una strategia efficace basata sulle tue reali esigenze.

            </div>
        </section>

        <section className="md:px-28">
            <div className="grid grid-cols-3 gap-4 px-8 md-20">
                <Box className="">
                  Vuoi capire meglio il <ReadMore><a href="/come-lavoriamo/">nostro metodo</a></ReadMore>
                </Box>
                <Box>
                  Vuoi scoprire <ReadMore><a href="/portfolio/">con chi abbiamo lavorato</a></ReadMore>
                </Box>
                <Box>
                  Vuoi approfondire i nostri servizi di <ReadMore><a href="/wen-marketing-analytics/">web marketing</a></ReadMore>
                </Box>
            </div>
            <div className="w-full flex flex-col md:flex-row md:justify-center md:items-center md:text-center pr-20 md-20 py-10">
              <div className="pt-2 flex flex-col md:flex-row justify-center items-start md:items-center">
                <div className="p-2 md:mx-4">
                  <Button typeformId="qT0c2QIo" bigger>
                    Iniziamo oggi!
                  </Button>
                </div>
                <div className="hidden md:flex items-center justify-center"></div>
              </div>
            </div>
        </section>
      </div>
    </CleanLayout>
  );
}

export default WebMarketingLocalPage;
